<template>
  <div class="services-page">
    <b-row class="m-0 pt-1">
      <b-col class="pr-0 pl-0">
        <div class="d-flex w-100">
          <div ref="navToggle" class="nav-toggle nav-toggle-left py-2 mr-3 my-auto" @click="toggleNavMobile">
            <fa-icon class="toggle-button m-auto" icon="bars" size="lg" />
          </div>
          <div class="title py-2 pr-2 pl-3 my-auto">Services</div>
          <div ref="serviceButtonToggle" class="nav-toggle d-flex my-auto ml-auto" @click="toggleServiceButtonsMobile">
            <fa-icon class="toggle-button m-auto" icon="ellipsis-v" size="lg" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="auto" class="d-flex flex-wrap align-items-center justify-content-end">
        <div v-if="selectedTab === 0" class="d-flex align-items-center justify-content-end flex-wrap w-auto py-2" :class="serviceButtonsClass">
          <b-button
            :class="[
              cancelServicesDisabled ? 'disabled-button-grey' : 'danger-button-outlined',
              'mr-1'
            ]"
            variant="primary-outline"
            aria-label="Cancel Services Button"
            :disabled="cancelServicesDisabled"
            @click="cancelServices"
          >
            Cancel Services
          </b-button>
          <b-button
            :class="[
              cancelServicesDisabled ? 'disabled-button-grey' : 'primary-button-outlined',
              'ml-2',
              'mr-2'
            ]"
            variant="primary-outline"
            aria-label="Manage Autopay Button"
            :disabled="manageAutopayDisabled"
            @click="manageAutopay(selectedIds)"
          >
            Manage Autopay
          </b-button>
          <div class="d-flex w-auto py-2" :class="serviceButtonsClass">
            <b-button
              :class="[
                !hasCompanies || loading ? 'disabled-button-grey' : 'primary-button',
                'ml-1',
                'mr-0'
              ]"
              style="margin-right: 2.5em"
              variant="primary"
              to="/hire-us"
              :disabled="!hasCompanies || loading"
              aria-label="Add Services Button"
            >
              Add Services
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="m-0">
    <div v-if="!loading" class="content-body">
      <div class="side-nav" :class="navClass">
        <div class="nav-button" :class="tabClass(0)" @click="selectedTab = 0">
          <div class="d-flex flex-column align-items-center text-center m-auto pa-2">
            <fa-icon class="mt-1" icon="home" size="lg" />
            <span class="mt-1">My Services</span>
          </div>
        </div>
        <div class="nav-button" :class="tabClass(1)" @click="selectedTab = 1">
          <div class="shared d-flex flex-column align-items-center text-center m-auto pa-2">
            <fa-icon class="mt-1" icon="handshake" size="lg" />
            <div class="mt-1">Shared with Me</div>
          </div>
        </div>
      </div>
      <div class="services-content">
        <div class="scrollable-content">
          <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div v-if="showCompanyFilters" class="d-flex align-items-center">
              <span class="hover-pointer d-flex align-items-center" @click="sortCompanies">
                <b-icon-sort-down v-if="sortedDescending" class="my-auto" scale="1.2" />
                <b-icon-sort-up-alt v-else class="my-auto" scale="1.2" />
                <span class="ml-2">Company Sort</span>
              </span>
            </div>
            <div v-if="showCompanyFilters" class="search-container d-flex align-items-center">
              <b-input-group>
                <b-form-input
                  v-model="searchFilter"
                  type="text"
                  placeholder="Search"
                />
                <b-input-group-text>
                  <feather-icon type="search" />
                </b-input-group-text>
              </b-input-group>
            </div>
          </div>
          <div v-show="selectedTab === 0">
            <div v-for="(company, idx) in ownedCompanies" :key="company.id" class="mb-4">
              <company-services
                :ref="`${company.id}-services`"
                v-model="expandedCompanies[idx]"
                :company="company"
                :search-filter="searchFilter"
              />
            </div>
            <div v-if="!ownedCompanies.length" class="text-center">
              No Companies Found
            </div>
          </div>
          <div v-show="selectedTab === 1">
            <div v-for="(company, idx) in sharedCompanies" :key="company.id" class="mb-4">
              <company-services
                v-model="expandedShared[idx]"
                shared :company="company"
                :search-filter="searchFilter"
              />
            </div>
            <div v-if="!sharedCompanies.length" class="text-center">
              <h5>No Shared Companies Found</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ct-centered-spinner v-else />
    <cancellation-modal
      :bus="bus"
      @cancel-complete="cancelServicesComplete"
    />
    <rick-roll-modal :bus="bus" />
  </div>
</template>

<script>

import Vue from 'vue'
import http from '@/http'
import {
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex'
import { companyServiceMixin } from '@/mixins/companyServiceMixin'
import { SET_SELECTED_SERVICES } from '@/store/mutations'
import { CLIENT_COMPANIES_INDEX_SIMPLE, CLIENT_COMPANIES_SHARED_SIMPLE } from '@/api/v3/endpoints'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'Services',
  components: {
    CompanyServices:   () => import('@/components/Services/CompanyServices/CompanyServices.vue'),
    FeatherIcon:       () => import('@/components/shared/FeatherIcon.vue'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
    CancellationModal: () => import('@/components/cancellation/CancellationModal'),
    RickRollModal:     () => import('@/components/cancellation/RickRollModal'),
  },
  mixins: [companyServiceMixin, makeToastMixin],
  data() {
    return {
      bus: new Vue(),
      expandedCompanies: [],
      expandedShared: [],
      loading: false,
      ownedCompanies: [],
      searchFilter: null,
      selectedTab: 0,
      sharedCompanies: [],
      showNavMobile: false,
      showServiceButtons: false,
      sortOwnedDescending: false,
      sortSharedDescending: false,
    }
  },
  computed: {
    ...mapGetters('account', ['activeAccountServices']),
    ...mapGetters('services', ['selectedServices']),
    cancelServicesDisabled() {
      return !this.selectedServices.length || this.loading
    },
    hasCompanies() {
      return this.selectedTab === 0 ? this.ownedCompanies.length > 0 : this.sharedCompanies.length > 0
    },
    manageAutopayDisabled() {
      return !this.hasCompanies || this.loading || !this.selectedAutoPayables.length
    },
    navClass() {
      return this.showNavMobile ? null : 'hidden'
    },
    serviceButtonsClass() {
      return this.showServiceButtons ? 'display-buttons' : 'hide-buttons'
    },
    selectedAutoPayables() {
      return this.selectedServices
        .filter(sel => this.autopayPayableSelectable(sel.status))
        .filter(this.isSelectable || this.isDomainService)
        .map(svc => svc.id)
    },
    showCompanyFilters() {
      return this.selectedTab === 0 && this.ownedCompanies.length > 0
        || this.selectedTab === 1 && this.sharedCompanies.length > 0
    },
    sortedDescending() {
      return this.selectedTab === 0 ? this.sortOwnedDescending : this.sortSharedDescending
    },
    selectedIds(){
      return this.selectedServices.map(svc => svc.id)
    },
  },
  async mounted() {
    this.loading = true
    this.setSelected([])
    await Promise.all([
      this.setActiveServicesCount(),
      this.processComplianceServices(this),
      this.loadIndexSimple(),
      this.loadSharedSimple(),
    ])
    this.loading = false
  },
  methods: {
    ...mapActions('account', [
      'setActiveServicesCount',
    ]),
    ...mapActions('services', [
      'processComplianceServices',
    ]),
    ...mapMutations('services', { setSelected: SET_SELECTED_SERVICES }),
    async cancelServices() {
      this.bus.$emit('showCancelServices', this.selectedServices)
    },
    async cancelServicesComplete() {
      const selectedCompanyIds = [...new Set(this.selectedServices.map(svc => svc.company_id))]
      selectedCompanyIds.forEach(cid => this.$refs[`${cid}-services`][0].loadServices())
      this.setSelected([])
      this.bus.$emit('showRickRoll')
      this.successToast('Success', 'Service cancellation request succeeded')
    },
    async loadIndexSimple() {
      this.expandedCompanies = this.ownedCompanies.map(() => false)
      const { data } = await http.get(CLIENT_COMPANIES_INDEX_SIMPLE, { params: {
          order_by: 'name',
          order_direction: 'desc',
        } })

      if(!data?.success) {
        this.errorToast('Error', 'Error loading companies')
        return
      }
      this.ownedCompanies = data.result
      this.expandedCompanies = this.ownedCompanies.map(() => true)
    },
    async loadSharedSimple() {
      this.expandedShared = this.sharedCompanies.map(() => false)
      const { data } = await http.get(CLIENT_COMPANIES_SHARED_SIMPLE, { params: {
          order_by: 'name',
          order_direction: 'desc',
        } })

      if(!data?.success) {
        this.errorToast('Error', 'Error loading companies')
        return
      }
      this.sharedCompanies = data.result
      this.expandedShared = this.sharedCompanies.map(() => true)
    },
    async sortCompanies() {
      if(this.selectedTab === 0) {
        if(this.ownedCompanies.length === 0) return
        this.sortOwnedDescending = !this.sortOwnedDescending
        this.ownedCompanies = this.ownedCompanies.sort((a, b) =>  this.sortOwnedDescending ?  a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
        this.expandedCompanies = this.ownedCompanies.map(() => true)
      } else {
        if(this.sharedCompanies.length === 0) return
        this.sortSharedDescending = !this.sortSharedDescending
        this.sharedCompanies.sort((a, b) => this.sortSharedDescending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
        this.expandedShared = this.sharedCompanies.map(() => true)
      }
    },
    tabClass(number) {
      return number === this.selectedTab ? 'selected' : null
    },
    toggleNavMobile() {
      this.showNavMobile = !this.showNavMobile
    },
    toggleServiceButtonsMobile() {
      this.showServiceButtons = !this.showServiceButtons
    },
  },
}
</script>

<style lang="scss" scoped>

.services-page {
  min-width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: -20px -15px;
  display: flex;
  flex-direction: column;

  .hover-pointer {
    :hover {
      cursor: pointer;
    }
  }

  .nav-toggle {
    display: none;
    * {
      display: none;
    }
  }

  .primary-button {
    background-color: #000864;
    border-color: #000864 !important;
    color: white;
    border-radius: 4px;

    &:not(:disabled):hover {
      background-color: #3F458A !important;
    }

    &-outlined {
      color: #000864 !important;
      border-color: #000864 !important;
      border-radius: 4px !important;
      background-color: white !important;
      height: 40px;

      &:not(:disabled):hover {
        background-color: #ECECFA !important;
      }
    }
  }
  button {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .danger-button-outlined {
    color: #BC2F2F !important;
    border-color: #BC2F2F !important;
    border-radius: 4px !important;
    height: 40px;

    &:not(:disabled):hover {
      background-color: #FCF0F0 !important;
    }
  }

  .disabled-button-grey {
    background-color: #e8e4e4;
    border-color: transparent;
    border-radius: 4px;
    color: #939292 !important;
    cursor: not-allowed;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
  }

  hr {
    border-top: 1px solid #BDBDBD;
    margin: 0;
  }

  .content-body {
    flex: 1;
    display: flex;

    .side-nav {
      background-color: #F8F8F8;
      min-height: 100%;
      min-width: 125px;
      width: 125px;
      margin-bottom: .25rem;

      .nav-button {
        display: flex;
        width: 100%;
        height: 100px;
        .shared {
          padding: 0 1.5rem;
        }
        &:hover {
          cursor: pointer;
        }

        &.selected {
          background-color: #EAE9FC
        }
      }

      @media (min-width: 768px) {
        * {
          display: flex !important;
        }
      }
    }

    .scrollable-content {
      flex: 1;
      overflow-y: auto;
      max-height: 80vh;
      padding: 15px;
    }

    .services-content {
      min-height: 100%;
      flex: 1;
      padding: 1rem;

      .search-container {
        min-width: 250px;
        .form-control {
          min-height: 40px !important;
          flex: 1;
          border-right: none;
        }

        .input-group-text {
          height: 40px !important;
          border-left: none;
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .primary-button, .primary-button-outlined, .danger-button-outlined {
      flex-grow: 1;
      height: auto;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .nav-toggle {
      display: flex;
      border: 1px solid #BDBDBD;
      border-radius: 8px 0 0 8px;
      cursor: pointer;
      width: 50px;
      height: 50px;
      * {
        display: block;
      }
    }

    .nav-toggle-left {
      border-radius: 0 8px 8px 0;
    }

    .d-flex {
      flex-direction: row !important;
    }

    .display-buttons {
      margin: 0 auto !important;
    }

    .hide-buttons {
      display: none !important;
      * {
        display: none !important;
      }
    }

    .content-body {
      flex-direction: column;

      .side-nav {
        min-height: 0;
        width: 100%;
        display: flex;
        flex-direction: row;

        .nav-button {
          flex: 1;
          height: 75px;
          padding: 0;
          svg {
            margin-right: 0.5rem;
          }
          .shared {
            padding: 0;
          }
        }

        &.hidden {
          display: none;
          * {
            display: none;
          }
        }
      }
      .services-content {
        min-height: 100%;
        flex: 1;

        .search-container {
          .form-control {
            min-width: 0;
          }

          .input-group-text {
            min-width: 0;
          }
        }
      }
    }
  }
}
</style>
